import React, { useState, useEffect } from "react";
import "./EventArticles.scss";
import { useInView } from 'react-intersection-observer';

import SmoothScroll from "../../../../components/SmoothScroll/SmoothScroll";
import 'animate.css/animate.css'; 

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useTheme } from "../../../../ThemeContext";
import ItemSlide from "./component/ItemSlide/ItemSlide";

import { getCurrentArticles } from "../../../../lib/firebase";

function EventArticles() {
  const { themeMode } = useTheme();
  const [animate, setAnimate] = useState(false);
  const [articles, setArticles] = useState([]);

  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });

  useEffect(() => {
    const fetchData = async () => {
      const articles =  await getCurrentArticles("Homepage");

      if(articles.length>0){
        setArticles(articles);
      }
    }

    fetchData()
    .catch(console.error);
  },[]);

  useEffect(() => {
    if (inView) {
      // console.log("In View: LaniakeaCapital");
      !animate && setAnimate(true)
    }
  }, [inView]);

  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="event-articles">
        <div className="row p-md-0">
          {/* <div className="col-sm-12 p-md-0">
            <div className="content-text d-flex flex-column align-items-center justify-content-evenly">
              <div className={`group-text hidden-content ${animateCss}`}
                style={{ animationDuration: '2s' }}
              >
                <p className="primary-text">LANIAKEA CAPITAL</p>
                <p className="second-text">Immeasurable Heavens of the Digital Asset Universe</p>
              </div>
            </div>
          </div> */}

          <div className="col-sm-12 p-md-0 p-0">
            <Swiper 
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              pagination={{
                // type: 'progressbar',
                clickable: true,
                // dynamicBullets: true,
              }}
              modules={[Autoplay,Pagination]}
              className={`mySwiper ${themeMode}`}
            >
              <SwiperSlide>
                <div className="content-text d-flex flex-column align-items-center justify-content-evenly">
                  <div className={`group-text hidden-content ${animateCss}`}
                    style={{ animationDuration: '2s' }}
                  >
                    <p className="primary-text">LANIAKEA CAPITAL</p>
                    <p className="second-text">Immeasurable Heavens of the Digital Asset Universe</p>
                  </div>
                </div>
              </SwiperSlide>

              { articles.map((item, index) => (
                <SwiperSlide key={`slide-item-${index}`}>
                  <ItemSlide item={item}></ItemSlide>
                </SwiperSlide>
              )) }
          </Swiper>
          </div>

          <div className="col-sm-12 p-md-0">
            <SmoothScroll targetId="section2">
              <img src="/images/scroll_icon.png" alt="Scroll Icon" />
            </SmoothScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventArticles;
