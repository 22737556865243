import axios from "axios";

const CryptoJS = require("crypto-js");
const { v4: uuidv4 } = require("uuid");

function stringToUuidWithKey(inputString, secretKey) {
    const hashedValue = CryptoJS.HmacSHA256(inputString, secretKey).toString(CryptoJS.enc.Hex);
    return uuidv4({
      random: Uint8Array.from(
        hashedValue.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
      )
    });
}

export function isAdminValid(dun, dpw){
    if(dun && dpw!=='' && dun && dpw!==''){
        if( dun===process.env.REACT_APP_ADMIN_USERNAME && dpw===process.env.REACT_APP_ADMIN_PASSWORD){
            return true
        }
    }

    return false;
}

export function validUserAdmin(username, password){
    if(username && username!=='' && password && password!==''){
        const decodeUsername = stringToUuidWithKey(username.toLowerCase(), process.env.REACT_APP_ADMIN_KEY);
        const decodePassword = stringToUuidWithKey(password, process.env.REACT_APP_ADMIN_KEY);
        if( decodeUsername===process.env.REACT_APP_ADMIN_USERNAME && decodePassword===process.env.REACT_APP_ADMIN_PASSWORD){
            return {
                dun: decodeUsername,
                dpw: decodePassword
            };
        }
    }

    return null;
}

export function setLocalStorage(dataObj){
    localStorage.setItem("laniakea-capital", JSON.stringify(dataObj));
}

export function getLocalStorage(name = 'laniakea-capital'){
    const local = localStorage.getItem(name);
    return local!=null ? JSON.parse(local) : '';
}

export function clearLocalStorage(name = 'laniakea-capital'){
    localStorage.removeItem(name);
}

export function updateLocalStorage(dataObj){
    const existAdminString = localStorage.getItem("laniakea-capital");
    
    if (existAdminString) {
      const existAdminObj = JSON.parse(existAdminString);
  
      Object.assign(existAdminObj, dataObj);
      localStorage.setItem("laniakea-capital", JSON.stringify(existAdminObj));
    } else {
      localStorage.setItem("laniakea-capital", JSON.stringify(dataObj));
    }
}

export function getFileExtension(url) {
    // Use the URL constructor to parse the URL
    const urlObject = new URL(url);
  
    // Get the path from the URL object
    const path = urlObject.pathname;
  
    // Use regular expressions to extract the file extension
    const matches = path.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
  
    if (matches) {
      // The file extension will be in matches[1]
      return matches[1].toLowerCase(); // Return the extension in lowercase
    } else {
      // If no extension is found, you can return a default value or handle the case accordingly
      return 'unknown';
    }
}

export function getMimeTypeFromExtension(fileExtension) {
    const extensionToMime = {
        // Add more extensions as needed
        pdf: 'application/pdf',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        txt: 'text/plain',
        html: 'text/html',
        js: 'application/javascript',
        css: 'text/css',

        // Microsoft Word
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

        // Microsoft Excel
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

        // Microsoft PowerPoint
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',

        // Microsoft Access
        mdb: 'application/msaccess',

        // Microsoft Publisher
        pub: 'application/vnd.ms-publisher',

        // Microsoft Visio
        vsd: 'application/vnd.visio',

        // Microsoft Outlook
        msg: 'application/vnd.ms-outlook',

        // Other Office formats
        rtf: 'application/rtf',
        odt: 'application/vnd.oasis.opendocument.text',
        ods: 'application/vnd.oasis.opendocument.spreadsheet',
        odp: 'application/vnd.oasis.opendocument.presentation',
    };
  
    const defaultMimeType = 'application/octet-stream'; // Default MIME type if extension is not found
  
    const lowerCaseExtension = fileExtension.toLowerCase();
    return extensionToMime[lowerCaseExtension] || defaultMimeType;
}

export const downloadFile = async (urlFile, newFileName, mineType) => {
    // let url; // Declare 'url' variable here

    try {
      const response = await axios.get(urlFile, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: mineType });
      const url = window.URL.createObjectURL(blob); // Initialize 'url' here
      const a = document.createElement('a');
      a.href = url;
      a.download = `${newFileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the File:', error);
    }
}
