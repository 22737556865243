import React, { useState, useEffect, useRef } from 'react';
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import './LoginSection.scss';

import { authentication } from '../../../../firebase-config';

import { useInView } from 'react-intersection-observer';
import { useTheme } from '../../../../ThemeContext';
import { useUserContext } from '../../../../UserContext';
import { useNavigate } from 'react-router-dom';
import { validUserAdmin } from '../../../../lib/utils';

function LoginSection() {
    const { themeMode } = useTheme();
    const navigate = useNavigate();

    const { login, logout } = useUserContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalid, setInvalid] = useState(false);

    const [animate, setAnimate] = React.useState(false);

    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });

    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    useEffect(() => {
        if (inView) {
            // console.log("In View: AboutUs");
            !animate && setAnimate(true)
        }
    }, [inView]);

    const submitLogin = (e) => {
        e.preventDefault();
        
        setInvalid(false);

        if(email!=='' && password!==''){
            const valid = validUserAdmin(email, password)
            if(valid){
                
                const userAdmin = {
                    "email": email.toLowerCase(),
                    "method": 'admin',
                    "name": "Admin",
                    "username": "Admin",
                    "displayName": "Admin",
                    ...valid 
                };

                login(userAdmin);
                navigate('/admin');
            }else{
                setInvalid(true);
            }
        }
    };

    const handleClickCancel = () => {
        setEmail('');
        setPassword('');
        setInvalid(false)
    }

    const handleChangeEmail = (e) => {
        // setInvalid(false);
        setEmail(e.target.value);
    };

    const handleChangePassword = (e) => {
        // setInvalid(false);
        setPassword(e.target.value);
    };

    return (
        <div className="container-fluid p-0" ref={ref}>
            <div className="login-section">
                <div className="row heading p-md-0">
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        <div className={`heading-text ${themeMode}`}>
                            Welcome to Laniakea Capital Admin
                        </div>
                    </div>
                </div>

                <div className={`row content p-md-0 ${themeMode}`}>
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        <div className='d-flex flex-row align-items-center justify-content-evenly'>
                            <div className='group-form d-flex flex-row align-items-center justify-content-evenly'>
                                <form onSubmit={submitLogin}>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            required
                                            placeholder="Please input admin email"
                                            autoComplete="off"
                                            value={email}
                                            onChange={handleChangeEmail}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="password"
                                            required
                                            placeholder="Please input admin password"
                                            autoComplete="off"
                                            value={password}
                                            onChange={handleChangePassword}
                                        />
                                    </div>

                                    <div className="form-group-button">
                                        <button type="submit" className="btn btn-verify">Login</button>

                                        <button className="btn btn-cancel" onClick={handleClickCancel}>Cancel</button>
                                    </div>

                                    {invalid && (
                                        <div className="form-group" style={{pt: "20px"}}>
                                            <div className="invalid">
                                                Login fail! Wrong email or password!
                                            </div>
                                        </div>
                                    )}
                                    

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginSection;
