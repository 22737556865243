import React from "react";
import Layout from "../../../../components/Layout/Layout";
import { useTheme } from "../../../../ThemeContext";
import { useInView } from "react-intersection-observer";
import Form from "./Form";
import ListData from "./ListData";
import "./ArticleManagement.scss";
import { useState, useEffect } from "react";
import {
  createArticle,
  updateArticle,
  getArticles,
  removeArticle
} from "../../../../lib/firebase";
import { useNotification } from "../../../../NotificationContext";
import { set } from "date-fns";

function ArticleManagement() {
  const { themeMode } = useTheme();
  const { openSnackbar } = useNotification();

  const [animate, setAnimate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true);
    }
  }, [inView]);
  const animateCss = animate ? "animate__animated animate__fadeIn" : "";
  const [selectedRow, setSelectedRow] = useState({
    eventTitle: "",
    eventContent: "",
    uploadOption: "",
    startDate: new Date(),
    endDate: new Date(),
    eventAttachment: null,
    images: ["", ""],
  });

  const [dataRows, setDataRows] = useState([]);
  const [isHaveNew, setIsHaveNew] = useState(false);
  const handleSubmitForm = async (data) => {
    const payload = {
      title: data.eventTitle,
      content: data.eventContent,
      uploadOption: data.uploadOption,
      imageDark: data.images[0],
      imageLight: data.images[1],
      startDate: data.startDate.getTime(),
      endDate: data.endDate.getTime(),
      attachment: data.eventAttachment,
    };
    if (selectedRow?.id) {
      try {
        setIsLoading(true);
        console.log(payload);
        await updateArticle(selectedRow.id, payload);
        setDataRows((prev) =>
          prev.map((row) => {
            if (row.id === selectedRow.id) {
              return {
                ...row,
                ...data,
              };
            }
            return row;
          })
        );
        setIsLoading(false);
        openSnackbar("success", "Update article successfully");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        openSnackbar("error", "Update article failed");
      }
    } else {
      try {
        setIsLoading(true);
        await createArticle(payload);
        setIsHaveNew((prev) => !prev);
        setIsLoading(false);
        openSnackbar("success", "Create article successfully");
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        openSnackbar("error", "Create article failed");
      }
    }
  };
  const handleDeleteRow = async (id) => {
    try {
      setIsLoading(true);
      await removeArticle(id);
      setDataRows((prev) => prev.filter((row) => row.id !== id));
      setIsHaveNew((prev) => !prev);
      if (selectedRow?.id === id) {
        setSelectedRow(null);
      }
      setIsLoading(false);
      openSnackbar("success", "Delete article successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openSnackbar("error", "Delete article failed");
    }
  };
  useEffect(() => {
    try {
      const getArticleList = async () => {
        const responses = await getArticles();
        const data = responses.map((response) => ({
          id: response.id,
          eventTitle: response.title,
          eventContent: response.content,
          uploadOption: response.uploadOption,
          startDate: new Date(response.startDate),
          endDate: new Date(response.endDate),
          images: [response.imageDark, response.imageLight],
          eventAttachment: response.attachment,
        }));
        setDataRows(data);
      };
      getArticleList();
    } catch (error) {
      console.log(error);
    }
  }, [isHaveNew]);
  return (
    <div className="container-fluid" ref={ref}>
      <div className="launch-soom">
        <div className="row heading p-md-0">
          <div className="col-sm-12 col-md-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className="heading-line">
                <hr className={themeMode} />
              </div>
              <div className={`heading-text ${themeMode}`}>
                MANAGE EVENT ARTICLES
              </div>
            </div>
          </div>
        </div>
        <div className="row content p-md-0" id="form-article">
          <div className="col-sm-12 col-md-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className={`content-text p-md-0 ${themeMode}`}>
                <p className={themeMode}>
                  <strong>IMPORTANT!</strong> The number of event
                  article is limited only 5 by the capacity of the
                  web server hosting it and the amount of available
                  storage space. Using edit button below to
                  reuse/update any event article for a new event
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div className={` p-md-0 hidden-content ${animateCss}`}>
              <div className={`content-text p-md-0 ${themeMode}`}>
                <Form
                  handleClearSelectedRow={() => {
                    setSelectedRow(null);
                  }}
                  selectedRow={selectedRow}
                  handleSubmitForm={handleSubmitForm}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className={`content-text p-md-0 ${themeMode}`}>
                <ListData
                  dataRows={dataRows}
                  handleGetDataRow={(row) => {
                    setSelectedRow((prev) => row);
                  }}
                  handleDeleteRow={handleDeleteRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleManagement;
