import React, { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// import { useTheme } from '../../ThemeContext';
import ToggleButton from "../ToggleButton/ToggleButton";
import { useTheme, THEME } from "../../ThemeContext";
import "./Header.scss";
import { useUserContext } from "../../UserContext";
import AvatarNoImage from "../Avatar";

function Header({ showNavbar = true }) {
  // const location = useLocation();
  const { themeMode, toggleTheme } = useTheme();
  const { user, logout, isLogin, isAdmin, verify } = useUserContext();

  // useEffect(() => {
  //   // console.log('Location has changed:', location.pathname);
  //   // Perform any other actions based on the change

  //   verify();
  // }, [location]);
  
  return (
    <header className={`fixed-header ${themeMode}`}>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="/logo.png" alt="Logo" className="logo" />
            <span className={`company-name ${themeMode}`}>
              Laniakea Capital
            </span>
          </Link>
          
          {showNavbar ? (
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {/* <span className="navbar-toggler-icon"></span>*/}
                <img
                  className="navbar-toggler-icon"
                  src={
                    themeMode === THEME.DARK
                      ? "/images/burger-menu-dark.png"
                      : "/images/burger-menu-light.png"
                  }
                  alt="Custom Icon"
                />
              </button>

              <div
                className={`collapse navbar-collapse ${themeMode}`}
                id="navbarNav"
              >
                <ul className="navbar-nav">
                  {isLogin && isAdmin && (
                    <li className="nav-item">
                      <NavLink
                        className={`nav-link ${themeMode}`}
                        to="/admin"
                        activeclassname="active"
                      >
                        Admin
                      </NavLink>
                    </li>
                  )}

                  <li className="nav-item">
                    <NavLink
                      className={`nav-link ${themeMode}`}
                      to="/investment-thesis"
                      activeclassname="active"
                    >
                      Investment Thesis
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link ${themeMode}`}
                      to="/research"
                      activeclassname="active"
                    >
                      Research
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link ${themeMode}`}
                      to="/careers"
                      activeclassname="active"
                    >
                      Careers
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={`nav-link ${themeMode}`}
                      to="/contact-us"
                      activeclassname="active"
                    >
                      Contact Us
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <div className="group-button ml-auto">
                      <ToggleButton themeMode={themeMode} toggleTheme={toggleTheme} />
                    </div>
                  </li>
                  
                  <li className="nav-item">
                    <div className="group-button ml-auto">
                      {user ? (
                        <button className="btn btn-logout" onClick={logout}>Logout</button>
                      ) : (
                        <NavLink 
                          className={`nav-link ${themeMode}`}
                          to="/login"
                        >
                          <button className="btn btn-login">Login</button>
                        </NavLink>
                      )}
                    </div>
                  </li>
                </ul>
              </div>

              <ToggleButton themeMode={themeMode} toggleTheme={toggleTheme} />

              {/* Add buttons to the right */}
              <div className="group-button ml-auto">
                {user ? (
                  <div className="profile">
                    <AvatarNoImage text={user?.displayName}
                      logout={logout}
                    />
                  </div>
                ) : (
                  <Link to="/login">
                    <button className="btn btn-client-login">Login</button>
                  </Link>
                )}
              </div>
            </>
          ) : (
            <ToggleButton themeMode={themeMode} toggleTheme={toggleTheme} />
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
