import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import "./JuniorInvestmentAnalyst.scss";
import { useTheme } from "../../../../ThemeContext";

function JuniorInvestmentAnalyst() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
      threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true)
    }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="junior-investment">

        <div className="row heading p-md-0">         
          <div className="col-sm-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              
              <div className={`heading-text ${themeMode}`}>Junior Investment Analyst</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="d-none d-sm-block"></div>
              <div className={`content-text p-md-0 ${themeMode}`}
                style={{ animationDuration: '2s' }}
              >
                <p className="careers-item-subtitle">Role Overview:</p>
                <ul className="careers-item-description">
                  <li>
                    Join our dynamic team at Laniakea as a Junior Investment Analyst. This position is vital to our investment decision-making process, as it involves a deep dive into potential investment opportunities and strategic insight formulation.
                  </li>
                </ul>

                <p className="careers-item-subtitle">Responsibilities:</p>
                <ul className="careers-item-description">
                  <li>
                    Conduct comprehensive industry research to identify emerging trends and investment opportunities.    
                  </li>
                  <li>
                    Develop, maintain, and enhance financial models to evaluate prospective investments.
                  </li>
                  <li>
                    Assist in the due diligence process, coordinating with other internal teams and external partners.
                  </li>
                  <li>
                    Produce detailed investment memos and present findings to the senior investment team.
                  </li>
                  <li>
                    Stay updated with market developments and continuously expand industry knowledge.
                  </li>  
                </ul>

                <p className="careers-item-subtitle">Qualifications:</p>
                <ul className="careers-item-description">
                  <li>Master’s degree in Finance.</li>
                  <li>Strong analytical, quantitative, and financial modeling skills.</li>
                  <li>Exceptional attention to detail and organizational abilities.</li>
                  <li>Excellent written and verbal communication skills.</li>
                  <li>Ability to thrive in a fast-paced, dynamic environment.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JuniorInvestmentAnalyst;
