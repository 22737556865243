import React, { useState, useEffect } from 'react';
import './LaunchSoon.scss';
import { useInView } from 'react-intersection-observer';
import { useTheme } from '../../../../ThemeContext';

function LaunchSoon() {
    const { themeMode } = useTheme();

    const [animate, setAnimate] = React.useState(false);
    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });
    React.useEffect(() => {
        if (inView) {
        // console.log("In View: AboutUs");
        !animate && setAnimate(true)
        }
    }, [inView]);
    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    return (
        <div className="container-fluid" ref={ref}>
            <div className="launch-soom">
                <div className="row heading p-md-0">
                    
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            <div className="heading-line"><hr className={themeMode}/></div>
                            <div className={`heading-text ${themeMode}`}>RESEARCH</div>
                        </div>
                    </div>
                    
                </div>

                <div className="row content p-md-0">
                    
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            {/* <div className="content-space d-none d-sm-block"></div> */}
                            <div className={`content-text p-md-0 ${themeMode}`}>
                                <p className={themeMode}>Launching soon. Stay tuned !</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default LaunchSoon;