import React, { useState, useEffect } from "react";
import "./InvestmentThesis.scss";

import Layout from "../../components/Layout/Layout";

import Investment from "./sections/Investment/Investment";
import GuidingPillars from "./sections/GuidingPillars/GuidingPillars";
import { useTheme } from "../../ThemeContext";
function InvestmentThesis() {
  const { themeMode } = useTheme();
  return (
    <Layout>
      <div className="container-fluid p-0">
        <div className="investment-thesis container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">
              
              <section id="section1" className={`full-scroll-section ${themeMode}`}>
                <Investment></Investment>
              </section>

              <section id="section2" className={`full-scroll-section ${themeMode}`}>
                <GuidingPillars></GuidingPillars>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InvestmentThesis;
