import React, { useState, useEffect } from "react";
import "./Home.scss";

import { useNavigate, useLocation } from 'react-router-dom';

import Layout from "../../components/Layout/Layout";
import EventArticles from "./sections/EventArticles/EventArticles";
import AboutUs from "./sections/AboutUs/AboutUs";
import OurTeam from "./sections/OurTeam/OurTeam";
import InvestmentEthos from "./sections/InvestmentEthos/InvestmentEthos";
import { useTheme } from "../../ThemeContext";
import { useUserContext } from "../../UserContext";


function Home() {
  const { login } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const encodedData = urlParams.get("data");

    if(encodedData){
      const decodedData = decodeURIComponent(encodedData);
      const user = JSON.parse(decodedData);
      console.log(user);

      login(user);
      navigate('/');
    }
  }, []);


  const { themeMode } = useTheme();
  return (
    <Layout showBgFooter={false}>
      <div className="container-fluid p-0">
        <div className="home container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">

              <section id="section1" className={`full-scroll-section m-0 ${themeMode}`}>
                <EventArticles></EventArticles>
              </section>
              
              <section id="section2" className={`full-scroll-section ${themeMode}`}>
                <AboutUs></AboutUs>
              </section>

              <section id="section3" className={`full-scroll-section ${themeMode}`}>
                <OurTeam></OurTeam>
              </section>

              <section id="section4" className={`full-scroll-section ${themeMode}`}>
                <InvestmentEthos></InvestmentEthos>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
