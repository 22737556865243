import React, { useState, useEffect } from "react";
import "./Admin.scss";
import Layout from "../../components/Layout/Layout";
import { useTheme } from "../../ThemeContext";
import AdminSection from "./sections/AdminSection/AdminSection";
import ArticleManagement from "./sections/ArticleManagement";
import UserManagement from "./sections/UserManagement";

function Admin() {
  const { themeMode } = useTheme();
  return (
    <Layout showBgFooter={false}>
      <div className="container-fluid p-0">
        <div className="admin container-content">
          <div style={{ height: "80px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">
              
              <section id="section1" className={`full-scroll-section ${themeMode}`}>
                <ArticleManagement/>
              </section>

              <section id="section2" className={`full-scroll-section ${themeMode}`}>
                <UserManagement/>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Admin;
