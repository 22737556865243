import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Input, Paper, TextField, Typography } from "@mui/material";
const FileUpload = ({
  label,
  onUpload,
  accept,
  multiple,
  src,
  error,
  minHeight,
  errorMessage,
  fileName,
  ...inputProps
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result;
          file.previews = binaryStr;
        };
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          onUpload(acceptedFiles);
        };
      });
      
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <Paper
      elevation={3}
      style={{ padding: "16px", marginTop: "16px" }}
      sx={{
        fontFamily: "'Open Sans', sans-serif",
        border: error ? "1px solid red" : "1px solid #ccc",
        minHeight: minHeight || "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        {...getRootProps()}
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        {src ? (
          <img src={src} alt="preview" style={{ width: "100%" }} />
        ) : (
          <>
            <input {...getInputProps()} {...inputProps} />
            <FileUploadOutlinedIcon sx={{ fontSize: "40px" }} />
            { fileName && <p
              className="file-name"
            >{fileName}</p> }
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: "bold",
              }}
            >
              {label}
            </Typography>
          </>
        )}
      </div>
    </Paper>
  );
};

export default FileUpload;
