import React, { useState, useEffect } from "react";
import "./AdminLogin.scss";

import Layout from "../../components/Layout/Layout";
import LoginSection from "./sections/LoginSection/LoginSection";
import { useTheme } from "../../ThemeContext";
function AdminLogin() {
  const { themeMode } = useTheme();
  return (
    <Layout showBgFooter={false} showNavbar={false}>
      <div className="container-fluid p-0">
        <div className="admin-login container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">
              
              <section id="section1" className={`full-scroll-section ${themeMode}`}>
                <LoginSection></LoginSection>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AdminLogin;
