import { CssBaseline, createTheme } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};
// Create a context to hold the theme
const ThemeContext = createContext();

// Create a custom hook to access the theme context
export function useTheme() {
  return useContext(ThemeContext);
}

// Theme Provider component
export function ThemeProvider({ children }) {
  const [themeMode, setTheme] = useState(THEME.LIGHT);
  // Function to toggle the theme
  const toggleTheme = () => {
    setTheme(prevTheme => {
      if (prevTheme === THEME.LIGHT) {
        return THEME.DARK;
      }
      return THEME.LIGHT;
    });
  };
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: '#007bff',
          },
          secondary: {
            main: '#6c757d',
          },
        },
      }),
    [themeMode],
  );

  return (
    <ThemeContext.Provider value={{ theme, themeMode, toggleTheme }}>
      <CssBaseline />
      {children}
    </ThemeContext.Provider>
  );
}