import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useTheme, THEME } from "../../../../ThemeContext";
import "./AboutUs.scss";

function AboutUs() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
    if (inView) {
      // console.log("In View: AboutUs");
      !animate && setAnimate(true);
    }
  }, [inView]);
  const animateCss = animate ? "animate__animated animate__fadeIn" : "";

  return (
    <div className="container-fluid" ref={ref}>
      <div className="about-us">
        <div className={`row heading p-md-0 ${themeMode}`}>
          <div className="col-sm-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className="heading-line">
                <hr className={`${themeMode}`} />
              </div>
              <div className={`heading-text ${themeMode}`}>ABOUT US</div>
            </div>
          </div>
        </div>

        <div className={`row content p-md-0 ${themeMode}`}>
          <div className="col-md-12 col-lg-6">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className="d-none d-sm-block"></div>
              <div
                className="content-text p-md-0"
                style={{ animationDuration: "2s" }}
              >
                <p>
                  Welcome to Laniakea — your gateway to the{" "}
                  <span className="highlight">
                    ‘immeasurable heavens’ of the digital asset universe
                  </span>
                  . Inspired by the vast galaxy supercluster, our name embodies
                  our ambition to act as a nexus between discerning investors
                  and groundbreaking projects, thereby facilitating mutual
                  growth through seamless connections.
                </p>

                <p>
                  Employing focused investment strategies, we curate a portfolio
                  designed for not just exponential growth but also as a
                  catalyst for technological innovation. Through strategic
                  partnerships, we cultivate vibrant ecosystems around our
                  investments, encouraging active community engagement and
                  forging synergies that amplify returns for all stakeholders.
                </p>

                <p>
                  At Laniakea, we serve as a bridge connecting investors to new
                  projects and protocols, unlocking access to a realm of
                  unexplored worlds brimming with investment opportunities.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <img
              className={`image-right hidden-content ${animateCss}`}
              src={
                themeMode === THEME.DARK
                  ? "/images/about_us_bg.png"
                  : "/images/about-us_light.png"
              }
              alt="About Us"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
