import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Footer.scss";
import { useTheme, THEME } from "../../ThemeContext";
import ToggleButton from "../ToggleButton/ToggleButton";
import { useUserContext } from "../../UserContext";

function Footer({ showBgFooter = true }) {
  const { themeMode, toggleTheme } = useTheme();
  const { isLogin } = useUserContext();
  return (
    <footer className={themeMode}>
      <div className="container-fluid p-0">
        {showBgFooter && (
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo">
                <img
                  className="footer-logo-image"
                  src={
                    themeMode === THEME.LIGHT
                      ? "/images/footer_light.svg"
                      : "/images/footer_bg.png"
                  }
                  alt="Laniakea Footer Logo"
                />
              </div>
            </div>
          </div>
        )}

        <div className="row info other-footer">
          <div className="col-md-8">
            <ul className={`navigation ${themeMode}`}>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/"
                  activeclassname="active"
                >
                  Home
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/research"
                  activeclassname="active"
                >
                  Research
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/contact-us"
                  activeclassname="active"
                >
                  Contact Us
                </NavLink>
              </li>
              
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/investment-thesis"
                  activeclassname="active"
                >
                  Investment Thesis
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/careers"
                  activeclassname="active"
                >
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/disclaimer"
                  activeclassname="active"
                >
                  Disclaimer
                </NavLink>
              </li>

              
            </ul>
          </div>
          <div className="col-md-4 icon-list">
            <ul className="list-unstyled social-icons vertical-icons">
              <li>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icon"
                    src="/images/logo-twitter.png"
                    alt="Telegram"
                  />
                </a>
              </li>
              <li>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icon"
                    src="/images/logo-telegram.png"
                    alt="Twitter"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row info mobile-footer">
          <div className="col-md-8">
            <ul className={`navigation ${themeMode}`}>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/"
                  activeclassname="active"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/investment-thesis"
                  activeclassname="active"
                >
                  Investment Thesis
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/research"
                  activeclassname="active"
                >
                  Research
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/careers"
                  activeclassname="active"
                >
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/contact-us"
                  activeclassname="active"
                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${themeMode}`}
                  to="/disclaimer"
                  activeclassname="active"
                >
                  Disclaimer
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-12 icon-list">
            <div className="col-md-8 d-flex justify-content-center mb-2">
              <div className="footer-logo">
                <ul className="list-unstyled social-icons vertical-icons">
                  <li>
                    <a href="/" target="_blank" rel="noopener noreferrer">
                      <img
                        className="icon"
                        src="/images/logo-twitter.png"
                        alt="Telegram"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank" rel="noopener noreferrer">
                      <img
                        className="icon"
                        src="/images/logo-telegram.png"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled social-icons vertical-icons">
                  {/* {!isLogin && (
                    <li>
                      <div className="login-button">
                        <Link to="/login">
                          <button className="btn login-button">Login</button>
                        </Link>
                      </div>
                    </li>
                  )} */}

                  <li>
                    <ToggleButton
                      themeMode={themeMode}
                      toggleTheme={toggleTheme}
                    />
                  </li>
                  {/* <li>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img
                      className="icon"
                      src="/images/logo-twitter.png"
                      alt="Telegram"
                    />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <img
                      className="icon"
                      src="/images/logo-telegram.png"
                      alt="Twitter"
                    />
                  </a>
                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
