import React, { useState, useEffect, useRef } from 'react';
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import './LoginSection.scss';

import { authentication } from '../../../../firebase-config';
import { loginUser, getUserByEmail } from '../../../../lib/firebase';

import { useInView } from 'react-intersection-observer';
import { useTheme } from '../../../../ThemeContext';
import { useUserContext } from '../../../../UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { validUserAdmin } from '../../../../lib/utils';
import { useNotification } from '../../../../NotificationContext';

function LoginSection() {
    const { themeMode } = useTheme();
    const navigate = useNavigate();
    const { openSnackbar } = useNotification();

    const { login, logout } = useUserContext();
    const [showForm, setShowForm] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalid, setInvalid] = useState(false);

    const [animate, setAnimate] = React.useState(false);

    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });

    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    useEffect(() => {
        if (inView) {
            // console.log("In View: AboutUs");
            !animate && setAnimate(true)
        }
    }, [inView]);

    useEffect(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://telegram.org/js/telegram-widget.js';
        scriptElement.async = true;
        document.body.appendChild(scriptElement);
    }, []);

    const fetchData = (user) => {
        fetch(process.env.REACT_APP_GOOGLE_SCRIPT, {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "text/plain;charset=utf-8",
            },
            body: JSON.stringify(user),
        })
        .then((res) => {
            console.log(res);

            login(user);
            navigate('/');
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const loginTwitter = () => {
        // navigate('/');
        const provider = new TwitterAuthProvider();
        signInWithPopup(authentication, provider)
            .then((result) => {
                if (result.user) {
                    console.log(result);

                    const user = {
                        "email": result?.user.email,
                        "method": 'twitter',
                        "name": result?.user.displayName,
                        "username": result?.user.email || '',
                        "displayName": result?.user.displayName || ''
                    }

                    fetchData(user);
                }
            }).catch((err) => {
                console.log(err)
            });
    };

    const submitLogin = async (e) => {
        e.preventDefault();
        
        setInvalid(false);

        if(email!=='' && password!==''){
            try{
                const isLogin = await loginUser(email, password);

                if(isLogin?.error){
                    openSnackbar("error", isLogin?.error);
                }else{
                    const user = await getUserByEmail(email);
                    if(user){
                        fetchData(user[0]);
                    }
                }
                
            }catch(err){
                console.log(err);
            }
        }
    };

    const handleClickLogin = (type) => {
        console.log(type);

        switch(type){
            case 'twitter':
                loginTwitter();
                break;

            case 'email':
                setShowForm(true);
                break;
    
            default:
                break;
        }
    }

    const handleClickCancel = () => {
        setEmail('');
        setPassword('');
        setShowForm(false);
        setInvalid(false)
    }

    const handleChangeEmail = (e) => {
        // setInvalid(false);
        setEmail(e.target.value);
    };

    const handleChangePassword = (e) => {
        // setInvalid(false);
        setPassword(e.target.value);
    };

    return (
        <div className="container-fluid p-0" ref={ref}>
            <div className="login-section">
                <div className="row heading p-md-0">
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        <div className={`heading-text ${themeMode}`}>
                            {!showForm ? `Welcome to Laniakea Capital - Please select your preferred login` : `Welcome to Laniakea Capital`  }
                        </div>
                    </div>
                </div>

                <div className={`row content p-md-0 ${themeMode}`}>
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        {!showForm && (
                            <div className='group-button d-flex flex-column align-items-center justify-content-evenly'>
                                <button className={`btn btn-login ${themeMode}`} onClick={()=>handleClickLogin('twitter')}>
                                    <img
                                        className="icon"
                                        src="/images/logo-twitter.png"
                                        alt="Twitter"
                                    />
                                    <span>Login with Twitter</span>
                                </button>

                                <button className={`btn btn-login ${themeMode}`} onClick={()=>handleClickLogin('email')}>
                                    <img
                                        className="icon"
                                        src="/images/logo-email.svg"
                                        alt="Twitter"
                                    />
                                    <span>Login with Email</span>
                                </button>
                            </div>
                        )}

                        {showForm && (
                            <div className='d-flex flex-row align-items-center justify-content-evenly'>
                                <div className='group-form d-flex flex-row align-items-center justify-content-evenly'>
                                    <form onSubmit={submitLogin}>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="email"
                                                required
                                                placeholder="Please input your email"
                                                autoComplete="off"
                                                value={email}
                                                onChange={handleChangeEmail}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="password"
                                                required
                                                placeholder="Please input your password"
                                                autoComplete="off"
                                                value={password}
                                                onChange={handleChangePassword}
                                            />
                                        </div>

                                        <div className="form-group-button">
                                            <button type="submit" className="btn btn-verify">Login</button>

                                            <button className="btn btn-cancel" onClick={handleClickCancel}>Cancel</button>
                                        </div>

                                        <div className="form-group">
                                            <div className='sign-up'>
                                                Don't have account ? <Link className={themeMode} to="/sign-up">Sign Up</Link>.
                                            </div>
                                        </div>

                                        {invalid && (
                                            <div className="form-group" style={{pt: "20px"}}>
                                                <div className="invalid">
                                                    Login fail! Wrong email or password!
                                                </div>
                                            </div>
                                        )}
                                        

                                    </form>
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginSection;
