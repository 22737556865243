import React, { useState, useEffect } from "react";
import "./ItemSlide.scss";
import { useInView } from 'react-intersection-observer';

import 'animate.css/animate.css'; 
import { useTheme, THEME } from "../../../../../../ThemeContext";
import { getFileExtension, getMimeTypeFromExtension, downloadFile} from "../../../../../../lib/utils"
import { FiDownload } from 'react-icons/fi';
import { useUserContext } from "../../../../../../UserContext";

function ItemSlide(props) {
  const { isLogin } = useUserContext();

  const { item } = props;

  const { themeMode } = useTheme();
  const [animate, setAnimate] = React.useState(false);

  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });

  React.useEffect(() => {
    if (inView) {
      // console.log("In View: LaniakeaCapital");
      !animate && setAnimate(true)
    }
  }, [inView]);

  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  const clickViewDetails = async (e) => {
    e.preventDefault();
    const timestampInSeconds = Math.floor(new Date().getTime() / 1000);

    let fileType = getFileExtension(item.attachment);
    let mineType = getMimeTypeFromExtension(fileType);
    let newFileName = `attachment_${timestampInSeconds}.${fileType}`

    await downloadFile(item.attachment, newFileName, mineType);
  }
  
  return (
    <>
      {item && (
        <div className="item-slide" ref={ref}>
          <div className="content-text d-flex flex-column align-items-center justify-content-evenly">
            <div className={`group-text hidden-content ${animateCss}`}
              style={{ animationDuration: '2s' }}
            >
              <p className="title">{item.title}</p>
              <p className="content">{item.content}</p>
              {isLogin && item?.attachment && (
                <div className={`link ${themeMode}`} onClick={clickViewDetails}>
                  <span>View Details</span>&nbsp;
                  <FiDownload className="icon"/> 
                </div>
              )}
            </div>
          </div>

          <div className={`background hidden-content ${animateCss}`}
            style={{ animationDuration: '3s' }}
          >
            <img src={
              themeMode === THEME.DARK ?
                item.imageDark: 
                item.imageLight              
              }
              alt="Item background"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ItemSlide;
