import './App.scss';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import InvestmentThesis from './pages/InvestmentThesis/InvestmentThesis';
import Research from './pages/Research/Research';
import Careers from './pages/Careers/Careers';
import ContactUs from './pages/ContactUs/ContactUs';

import Login from './pages/Login/Login';
import Disclaimer from './pages/Disclaimer/Disclaimer';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { useTheme } from './ThemeContext';
import { useUserContext } from './UserContext';

import AdminLogin from './pages/AdminLogin/AdminLogin';
import SignUp from './pages/SignUp/SignUp';
import Admin from './pages/Admin/Admin';

// const StyledApp = styled.div`
//   background-color: ${props => (props.theme === 'light' ? 'white' : 'black')};
//   color: ${props => (props.theme === 'light' ? 'black' : 'white')};
// `;

function App() {
  const { theme } = useTheme();
  const { isLogin, isAdmin } = useUserContext();
  console.log(isLogin, isAdmin);

  return (
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="/investment-thesis" element={<InvestmentThesis />}></Route>
          <Route path="/research" element={<Research />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />} ></Route>

          <Route path="/login" element={
            isLogin ? <Navigate to="/" /> : <Login />
          } ></Route>
          <Route path="/sign-up" element={
            isLogin ? <Navigate to="/" /> : <SignUp />
          } ></Route>

          <Route path="/admin" element={
            isAdmin ? <Admin /> : <AdminLogin /> 
          } ></Route>
          
        </Routes>
      </Router>
  );
}

export default App;
