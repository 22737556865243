import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import "./FinanceAndOperationalAnalyst.scss";
import { useTheme } from "../../../../ThemeContext";

function FinanceAndOperationalAnalyst() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
      threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true)
    }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="finance-and-operational-analyst">

        <div className="row heading p-md-0">         
          <div className="col-sm-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              
              <div className={`heading-text ${themeMode}`}>Finance and Operational Analyst</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="d-none d-sm-block"></div>
              <div className={`content-text p-md-0 ${themeMode}`}
                style={{ animationDuration: '2s' }}
              >
                <p className="careers-item-subtitle">Role Overview:</p>
                <ul className="careers-item-description">
                  <li>
                    Laniakea is on the lookout for a diligent Finance and Operational Analyst to spearhead our financial planning and ensure operational efficiency. This role intersects finance with operations, ensuring the company's financial health and streamlined processes.
                  </li>
                </ul>

                <p className="careers-item-subtitle">Responsibilities:</p>
                <ul className="careers-item-description">
                  <li>Oversee budgeting, forecasting, and financial analysis processes.</li>
                  <li>Analyze current financial performance against forecasts and assess variances.</li>
                  <li>Provide insights to management on financial trends, potential risks, and investment decisions.</li>
                  <li>Collaborate with various departments to optimize operational processes and reduce costs.</li>
                  <li>Prepare detailed reports and presentations for senior management and stakeholders.</li>
                  <li>Ensure compliance with financial regulations and standards.</li>
                </ul>

                <p className="careers-item-subtitle">Qualifications:</p>
                <ul className="careers-item-description">
                  <li>Bachelor’s degree in Finance, Accounting, or a related field.</li>
                  <li>Proven experience in financial planning, budgeting, and forecasting.</li>
                  <li>Strong analytical skills with the ability to interpret complex financial data.</li>
                  <li>Proficiency in financial software and tools.</li>
                  <li>Excellent organizational skills and attention to detail.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceAndOperationalAnalyst;
