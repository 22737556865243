import React, {useRef, useEffect, useState} from 'react';
import './ToggleButton.scss'; // Create a CSS file for button styling
import { THEME } from '../../ThemeContext';

function ToggleButton({ themeMode, toggleTheme }) {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to update the window size
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add the event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <button className={`toggle-button ${themeMode}`} onClick={toggleTheme}>
      <p> 
        {
          windowSize.width < 1200 ? 
          (themeMode === THEME.LIGHT ? 'Light' : 'Dark') : 
          (themeMode === THEME.LIGHT ? 'Light Mode' : 'Dark Mode')
        }
      </p>
      
      <img src={
        themeMode === THEME.LIGHT ? "/images/moon_light.svg" :
        "/images/moon.svg"} alt="dark-mode" className="dark-mode" 
      />
    </button>
  );
}

export default ToggleButton;