import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import "./CommunityManager.scss";
import { useTheme } from "../../../../ThemeContext";


function CommunityManager() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
      threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
      if (inView) {
      !animate && setAnimate(true)
      }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="community-manager">

        <div className="row heading p-md-0">         
          <div className="col-sm-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              
              <div className={`heading-text ${themeMode}`}>Community Manager</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="d-none d-sm-block"></div>
              <div className={`content-text p-md-0 ${themeMode}`}
                style={{ animationDuration: '2s' }}
              >
                <p className="careers-item-subtitle">Role Overview:</p>
                <ul className="careers-item-description">
                  <li>
                    Laniakea is seeking a passionate Community Manager to foster our digital presence and engage with our growing web3 community. This role is central to maintaining the company's image, building relationships, and ensuring consistent communication with our stakeholders.
                  </li>
                </ul>

                <p className="careers-item-subtitle">Responsibilities:</p>
                <ul className="careers-item-description">
                  <li>Manage, nurture, and grow our online communities across various social media platforms.</li>
                  <li>Craft and post regular updates, engage in discussions, and respond to community inquiries.</li>
                  <li>Develop community engagement strategies and campaigns.</li>
                  <li>Monitor online conversations and provide feedback to internal teams.</li>
                  <li>Organize webinars, AMAs, and other community events.</li>
                  <li>Track community metrics and provide reports on engagement and growth.</li>
                </ul>

                <p className="careers-item-subtitle">Qualifications:</p>
                <ul className="careers-item-description">
                  <li>Bachelor’s degree in Communications, Marketing, or a related field.</li>
                  <li>Prior experience managing web3 communities is a must.</li>
                  <li>Strong understanding of social media platforms and community engagement tools.</li>
                  <li>Excellent communication and interpersonal skills.</li>
                  <li>Ability to multitask and manage multiple projects simultaneously.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityManager;
