import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "@mui/material/styles/styled";
import FileUpload from "./FileUpload";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from '@mui/material/CircularProgress';
import "./form.scss";
import { THEME, useTheme } from "../../../../../ThemeContext";
import { FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";

const SubmitButton = styled(Button)({
  backgroundColor: "#D41876",
  marginRight: "20px",
  "&:hover": {
    backgroundColor: "#D41876",
    opacity: 0.8,
  },
});
const ClearButton = styled(Button)({
  backgroundColor: "#6F5D5D",
  "&:hover": {
    backgroundColor: "#6F5D5D",
    opacity: 1.1,
  },
});
function Form({ selectedRow, handleClearSelectedRow, handleSubmitForm, isLoading }) {
  const { themeMode } = useTheme();

  const [eventData, setEventData] = useState({
    eventTitle: "",
    eventContent: "",
    uploadOption: "All",
    startDate: new Date(),
    endDate: new Date(),
    eventAttachment: null,
    images: ["", ""],
  });
  const [dataPreview, setDataPreview] = useState([null, null]);
  const [attachmentName, setAttachmentName] = useState(
    selectedRow?.attachment || ""
  );

  useEffect(() => {
    setEventData({
      id: selectedRow?.id || "",
      eventTitle: selectedRow?.eventTitle || "",
      eventContent: selectedRow?.eventContent || "",
      uploadOption: selectedRow?.uploadOption || "All",
      startDate: selectedRow?.startDate || new Date(),
      endDate: selectedRow?.endDate || new Date(),
      eventAttachment: selectedRow?.eventAttachment || null,
      images: selectedRow?.images || ["", ""],
    });
    setAttachmentName(selectedRow?.eventAttachment || "");
  }, [selectedRow]);
  
  const handleClear = () => {
    handleClearSelectedRow();
    setEventData({
      eventTitle: "",
      eventContent: "",
      uploadOption: "All",
      startDate: new Date(),
      endDate: new Date(),
      eventAttachment: null,
      images: ["", ""],
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  };

  const handleChangeUploadOption = (event) => {
    const { name, value } = event.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
  }
  

  const handleDateChange = (date, field) => {
    setEventData({
      ...eventData,
      [field]: date,
    });
  };

  const handleAttachmentUpload = (files ) => {
    setEventData({
      ...eventData,
      eventAttachment: files[0],
    });
    console.log(files[0].name);
    setAttachmentName(`${files[0].name}`);
  };

  const handleImageUpload = (files, index) => {
    const images = [...eventData.images];
    const previews = files[0].previews;
    delete files[0].previews;
    images[index] = files[0];
    setDataPreview((prev) => {
      const newData = [...prev];
      newData[index] = `${previews}`;
      return newData;
    });
    setEventData({
      ...eventData,
      images,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    if (eventData.images[0] === "") {
      alert("Please enter dark image");
      return;
    }
    if (eventData.images[1] === "") {
      alert("Please enter light image");
      return;
    }
    handleSubmitForm(eventData);
  };

  return (
    <Container maxWidth="100vw" className="form-container">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={8} md={6}>
            <InputLabel
              htmlFor="e-title"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Event Title
            </InputLabel>
            <TextField
              id="e-title"
              label={""}
              name="eventTitle"
              fullWidth
              required
              value={eventData.eventTitle}
              onChange={handleInputChange}
              inputProps={{ maxLength: 100 }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
              }}
            />
            <p className={`helper-text ${themeMode}`}>
              Characters left: {100 - eventData.eventTitle.length}
            </p>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>

          <Grid item xs={12} sm={8} md={6}>
            <InputLabel
              htmlFor="eventContent"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Event Content
            </InputLabel>
            <TextField
              name="eventContent"
              fullWidth
              required
              multiline
              rows={4}
              maxRows={4}
              value={eventData.eventContent}
              onChange={handleInputChange}
              inputProps={{ maxLength: 200 }}
              sx={{
                borderRadius: "5px",
              }}
            />

            <p className={`helper-text ${themeMode}`}>
              Characters left: {200 - eventData.eventContent.length}
            </p>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>

          <Grid item xs={12} sm={8} md={6}>
            <InputLabel
              htmlFor="e-title"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Upload Option
            </InputLabel>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="uploadOption"
              id="uploadOption"
              value={eventData.uploadOption}
              onChange={handleChangeUploadOption}
              required
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              <FormControlLabel value="All" control={<Radio />} label="All" />
              <FormControlLabel value="Homepage" control={<Radio />} label="Homepage" />
              <FormControlLabel value="Research" control={<Radio />} label="Research" />
            </RadioGroup>
            
            {/* <Select
              labelId="demo-simple-select-label"
              id="uploadOption"
              value={eventData.uploadOption}
              name="uploadOption"
              label={""}
              onChange={handleChangeUploadOption}
              fullWidth
              required
              sx={{
                backgroundColor: "#fff",
                borderRadius: "5px",
              }}
            >
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Homepage'}>Homepage</MenuItem>
              <MenuItem value={'Research'}>Research</MenuItem>
            </Select> */}
            <p className={`helper-text ${themeMode}`}></p>
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>

          <Grid item xs={12} sm={4} md={3}>
            <InputLabel
              htmlFor="startDate"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Start Date
            </InputLabel>
            <DatePicker
              id="startDate"
              required
              value={eventData.startDate}
              onChange={(date) => handleDateChange(date, "startDate")}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <InputLabel
              htmlFor="endDate"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              End Date
            </InputLabel>
            <DatePicker
              id="endDate"
              required
              value={eventData.endDate}
              onChange={(date) => handleDateChange(date, "endDate")}
              renderInput={(params) => <TextField {...params} />}
              minDate={eventData.startDate}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Grid>
          <Grid item xs={0} sm={4} md={6}></Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            sx={{
              marginTop: "20px",
            }}
          >
            <InputLabel
              htmlFor="attachment"
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Event Article Attachment 
            </InputLabel>
            <FileUpload
              id="attachment"
              onUpload={handleAttachmentUpload}
              accept=".pdf, .doc, .docx"
              fileName={attachmentName}
            />
          </Grid>
          <Grid item xs={0} sm={8} md={6}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            sx={{
              marginTop: "20px",
            }}
          >
            <InputLabel
              htmlFor="image1"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
              }}
            >
              Dark Image
            </InputLabel>
            <FileUpload
              id="image1"
              label="Upload Event Background Image to be displayed on Dark Mode"
              onUpload={(files) => handleImageUpload(files, 0)}
              accept="image/*"
              src={dataPreview[0] || eventData.images[0]}
              minHeight="400px"
              name="image1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <InputLabel
              htmlFor="image2"
              required
              sx={{
                color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Light Image
            </InputLabel>
            <FileUpload
              id="image2"
              label="Upload Event Background Image to be displayed on Light Mode"
              onUpload={(files) => handleImageUpload(files, 1)}
              accept="image/*"
              src={dataPreview[1] || eventData.images[1]}
              minHeight="400px"
              name="image2"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: "20px",
            }}
          >
            {isLoading ? 
            <CircularProgress />
              :
              <>
              <SubmitButton type="submit" variant="contained">
                {eventData.id ? "Update" : "Create"}
              </SubmitButton>
              <ClearButton variant="contained" onClick={()=>{
                setDataPreview([null, null])
                handleClear()
              }}>
                Clear
              </ClearButton>
              </>
              }
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default Form;
