import React, { useState, useEffect } from 'react';
import './Investment.scss';
import { useTheme } from '../../../../ThemeContext';
import { useInView } from 'react-intersection-observer';

function Investment() {
    const { themeMode } = useTheme();

    const [animate, setAnimate] = React.useState(false);
    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });
    React.useEffect(() => {
        if (inView) {
        // console.log("In View: AboutUs");
        !animate && setAnimate(true)
        }
    }, [inView]);
    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    return (
        <div className="container-fluid" ref={ref}>
            <div className="investment">
                <div className="row heading p-md-0">
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            <div className="heading-line"><hr className={themeMode}/></div>
                            <div className={`heading-text ${themeMode}`}>LANIAKEA INVESTMENT THESIS</div>
                        </div>
                    </div>
                </div>

                <div className="row content p-md-0">
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            {/* <div className="content-space d-none d-sm-block"></div> */}
                            <div className="content-text row p-md-0">
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Venture Equity</p>
                                        <p className={themeMode}>Our deep roots in the blockchain ecosystem grant us a unique vantage point. We have a pivotal exposure to equity in companies sculpting the blockchain landscape. With a stellar reputation and unparalleled technical acumen, we lead a significant portion of our portfolio company investments.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Early-Stage Tokens</p>
                                        <p className={themeMode}>Beyond traditional equity, our eyes are set on tokens that redefine efficiency and scalability. Our strategy involves engaging in token investments during their nascent stages, often at a preferential rate.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Liquid Tokens</p>
                                        <p className={themeMode}>Our prowess extends to the most liquid digital assets in the market. Leveraging our profound market insights and technical expertise, we maneuver through token trading, capitalizing on market nuances.</p>
                                    </div>
                                </div>
   
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Investment;