import React, { useState, useEffect } from "react";
import "./InvestmentEthos.scss";
import { useTheme, THEME } from "../../../../ThemeContext";
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

function InvestmentEthos() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
    if (inView) {
      // console.log("In View: InvestmentEthos");
      !animate && setAnimate(true)
    }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="team">
        <div className={`row heading p-md-0 ${themeMode}`}>
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="heading-line">
                <hr className={`${themeMode}`} />
              </div>
              <div className={`heading-text ${themeMode}`}>INVESTMENT ETHOS</div>
            </div>
          </div>
        </div>

        <div className={`row content p-md-0 ${themeMode}`}>
          <div className="col-md-12 col-lg-6">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="content-text p-md-0">
                <p>
                  At Laniakea, we're deeply entrenched in the vast expanse of the digital asset milieu. From stakes in groundbreaking blockchain initiatives to emerging and agile tokens, our prowess consistently places us at the pinnacle of opportunity.
                </p>

                <p>
                  Our investment compass is guided by three cardinal tenets: unrivalled value propositions, tangible market resonance, and collaborations with the sector's most distinguished teams.
                </p>

                <p>
                  Inspired by the promise of a self-sovereign data epoch, we ardently advocate for a brighter, web3-centric future, wholeheartedly supporting those visionary enterprises poised to sculpt a more harmonious digital tomorrow.
                </p>

                <p>
                  <Link className={themeMode} to="/investment-thesis">Learn More</Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-6">
            <img className={`image-right hidden-content ${animateCss}`} src={
              themeMode === THEME.DARK ?
              "/images/investment_ethos_bg.png": 
              "/images/investment_ethos_bg_light.png"
              } alt="Investment Ethos" />
          </div>

        </div>
      </div>
    </div>
  );
}

export default InvestmentEthos;
