import React from "react";
import { useTheme } from "../../../../ThemeContext";
import { useInView } from "react-intersection-observer";

import ListData from "./ListData";
import "./UserManagement.scss";
import { useState, useEffect } from "react";

import {
  getUsers,
  updateBlockUser
} from "../../../../lib/firebase";
import { useNotification } from "../../../../NotificationContext";
import { set } from "date-fns";

function UserManagement() {
  const { themeMode } = useTheme();
  const { openSnackbar } = useNotification();

  const [animate, setAnimate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0.2,
  });
  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true);
    }
  }, [inView]);
  const animateCss = animate ? "animate__animated animate__fadeIn" : "";

  // const [selectedRow, setSelectedRow] = useState({
  //   eventTitle: "",
  //   eventContent: "",
  //   uploadOption: "",
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   eventAttachment: null,
  //   images: ["", ""],
  // });

  const [dataRows, setDataRows] = useState([]);
  const [isHaveNew, setIsHaveNew] = useState(false);

  const handleUnblockRow = async (selectedRow) => {
    try {
      setIsLoading(true);
      await updateBlockUser(selectedRow.email, false);
      setDataRows((prev) =>
        prev.map((row) => {
          if (row.id === selectedRow.id) {
            return {
              ...row,
              block: false
            };
          }
          return row;
        })
      );
      setIsLoading(false);
      openSnackbar("success", "Unblock user successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openSnackbar("error", "Unblock user failed");
    }
  };

  const handleBlockRow = async (selectedRow) => {
    try {
      setIsLoading(true);
      await updateBlockUser(selectedRow.email, true);
      setDataRows((prev) =>
        prev.map((row) => {
          if (row.id === selectedRow.id) {
            return {
              ...row,
              block: true
            };
          }
          return row;
        })
      );
      setIsLoading(false);
      openSnackbar("success", "Block user successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      openSnackbar("error", "Block user failed");
    }
  };

  useEffect(() => {
    try {
      const getUserList = async () => {
        const responses = await getUsers(-1);

        const data = responses.map((response) => ({
          id: response.id,
          display: response.display,
          username: response.username,
          email: response.email,
          block: response.block,
          method: response.method   
        }));
        setDataRows(data);
      };
      getUserList();
    } catch (error) {
      console.log(error);
    }
  }, [isHaveNew]);

  return (
    <div className="container-fluid" ref={ref}>
      <div className="launch-soom">
        <div className="row heading p-md-0">
          <div className="col-sm-12 col-md-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className="heading-line">
                <hr className={themeMode} />
              </div>
              <div className={`heading-text ${themeMode}`}>
                MANAGE USERS
              </div>
            </div>
          </div>
        </div>
        
        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div
              className={`split-container p-md-0 hidden-content ${animateCss}`}
            >
              <div className={`content-text p-md-0 ${themeMode}`}>
                <ListData
                  dataRows={dataRows}
                  // handleGetDataRow={(row) => {
                  //   setSelectedRow((prev) => row);
                  // }}
                  // handleDeleteRow={handleDeleteRow}

                  handleUnblockRow={handleUnblockRow}

                  handleBlockRow={handleBlockRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  );
}

export default UserManagement;
