import React from "react";
import "./ContactUsSection.scss";
import { useInView } from 'react-intersection-observer';
import { useTheme } from "../../../../ThemeContext";

const URL =
  "https://script.google.com/macros/s/AKfycbx6IU3G7taOJb6H1A1m9efZjAfbLeaYiGSnemdB4RmoC0KKtmAUo90i48eELidrgnDG/exec";

function ContactUsSection() {
  const [animate, setAnimate] = React.useState(false);

  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });

  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true)
    }
  }, [inView]);

  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  const submit = (e) => {
    e.preventDefault();
    const { name, email, phone, message } = e.target;
    fetch(URL, {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify({
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      }),
    })
      .then((res) => {
        console.log(res);
        alert("Message sent successfully!");
        e.target.reset();
      })
      .catch((err) => {
        console.log(err);
        alert("Message failed to send!");
      });
  };
  const { themeMode } = useTheme();
  return (
    <div className="container-fluid" ref={ref}>
      <div className="contact-us-section">
        <div className="row heading p-md-0 ">
          
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="heading-line">
                <hr 
                  className={themeMode}
                />
              </div>
              <div className={`heading-text ${themeMode}`}>CONTACT US</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          
          <div className="col-md-12 col-lg-6">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className={`content-text p-md-0 ${themeMode}`}>
                <p className="col-md-10">
                  <span className="highlight">We would like to hear from you</span> - be it an excellent new protocol, strategic partnerships or for us to participate in your communities. We look forward to journeying through the limitless opportunities in the digital asset and web3 realms with you.
                </p>

                <p className="title">Projects and Protocols</p>

                <p>If you're an innovative project in the web3 or digital asset space, Laniakea is the strategic partner you've been looking for. Beyond providing capital, we offer:</p>

                <ul>
                  <li><strong>Fundraising Strategy</strong>: Expert advice on navigating the complexities of raising capital.</li>
                  <li><strong>Strategic Partnerships</strong>: Introductions to other projects and founders for beneficial collaborations.</li>
                  <li><strong>Community Development</strong>: Leverage our expertise in community management to grow and engage your user base.</li>
                </ul>

                <p>We are always enthused to meet new founders and projects. Do reach out to us today and let us join you on this journey.</p>
              </div>
            </div>
          </div>

          <div className={`col-sm-12 col-md-6 hidden-content ${animateCss}`}>
            <form className={themeMode} onSubmit={submit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" className="form-control" id="name" />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="form-control" id="email" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Project Name <span style={{fontStyle:"italic"}}>(if applicable):</span></label>
                <input type="text" className="form-control" id="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Send Message
              </button>
            </form>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default ContactUsSection;
