import React, { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import "./CareersSection.scss";
import { useTheme } from "../../../../ThemeContext";

function CareersSection() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
      threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
      if (inView) {
      !animate && setAnimate(true)
      }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="careers-section">

        <div className="row heading p-md-0">         
          <div className="col-sm-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="heading-line">
                <hr 
                  className={themeMode}
                />
              </div>
              <div className={`heading-text ${themeMode}`}>CAREERS</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="d-none d-sm-block"></div>
              <div className={`content-text p-md-0 ${themeMode}`}
                style={{ animationDuration: '2s' }}
              >
                <p>
                  We at Laniakea are fueled by passion, innovation, and the boundless potential of the web3 space. As we continue our journey, we're eager to meet talented individuals who share our vision and drive. If you're enthusiastic about shaping the future and thrive in a dynamic environment, we're excited to meet you. Join us, and let's navigate the vast expanse of opportunities together
                </p>

                <p>
                  Please send your resume with a cover letter to <a className="email" target="_blank" href="mailto:contactus@laniakeacapital.xyz">contactus@laniakeacapital.xyz</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareersSection;
