import React, { useState, useEffect } from "react";
import "./OurTeam.scss";
import { useTheme, THEME } from "../../../../ThemeContext";
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

function OurTeam() {
  const { themeMode } = useTheme();

  const [animate, setAnimate] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });
  React.useEffect(() => {
    if (inView) {
      console.log("In View: OurTeam");
      !animate && setAnimate(true)
    }
  }, [inView]);
  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  return (
    <div className="container-fluid" ref={ref}>
      <div className="our-team">
        <div className="row heading p-md-0">
          
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="heading-line">
              <hr className={`${themeMode}`} />
              </div>
              <div className={`heading-text ${themeMode}`}>OUR TEAM</div>
            </div>
          </div>
          
        </div>

        <div className={`row content p-md-0 ${themeMode} our-team-content`}>
          
          <div className="photo col-md-12 col-lg-6">
            <img className={`image-right hidden-content ${animateCss}`} src={
              themeMode === THEME.DARK ?
              "/images/our_team_bg.png": 
              "/images/our-team_light.png"
              } alt="Our Team" />
          </div>

          <div className="paragraph col-md-12 col-lg-6">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="content-text p-md-0">
                <p>
                  Our team hails from a rich tapestry of backgrounds, encompassing Investment Banking, Private Equity, and Venture Capital. We possess a distinguished proficiency spanning from the established terrains of investment to the vibrant realms of blockchain and cryptocurrency.
                </p>

                <p>
                  We actively cultivate affiliations with industry vanguards, unveiling novel avenues and imparting added value to our portfolio enterprises. In this rapidly evolving sector, collaboration remains our fundamental ethos, and we're steadfast in forging lasting bonds throughout the ecosystem.
                </p>

                <p>
                  Our team boasts profound ties within the blockchain and cryptocurrency circles, affording us unparalleled perspectives on nascent trends and innovations.
                </p>

                <p>
                  Moreover, we're perpetually on the lookout for the most luminous and fervent talents to augment our ensemble. Check out our latest <Link className={themeMode} to="/careers">job opportunities</Link>.
                </p>
                
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default OurTeam;
