import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import {isAdminValid, updateLocalStorage, clearLocalStorage, getLocalStorage} from "../src/lib/utils";

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  const isLogin = useMemo(() => {
    return !!(user?.email || user?.displayName || user?.name);
  }, [user]);

  const isAdmin = useMemo(() => {
    const admin = getLocalStorage();
    const _isAdminValid = isAdminValid(admin.dun, admin.dpw);
    const _isAdminMethod = user?.method === 'admin' ? true : false;

    return !!( _isAdminMethod && _isAdminValid);
  }, [user]);

  useEffect(() => {
    const userRaw = localStorage.getItem("user");
    const user = JSON.parse(userRaw);

    setUser(user);
  }, []);

  const login = (user) => {
    if(user?.dun && user?.dpw){
      updateLocalStorage({dun:user?.dun, dpw:user?.dpw});

      delete user.dun;
      delete user.dpw;
    }

    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");

    clearLocalStorage()
  };

  const verify = () => {
    const userRaw = localStorage.getItem("user");
    const user = JSON.parse(userRaw);

    // console.log(user);
    if(user==null)
      setUser(user);
  }

  return (
    <UserContext.Provider value={{ user, login, logout, verify, isLogin, isAdmin }}>
      {children}
    </UserContext.Provider>
  );
}
