import React from "react";
import "./AdminSection.scss";
import { useInView } from 'react-intersection-observer';
import { useTheme } from "../../../../ThemeContext";


function AdminSection() {
  const [animate, setAnimate] = React.useState(false);

  const [ref, inView] = useInView({
    threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
  });

  React.useEffect(() => {
    if (inView) {
      !animate && setAnimate(true)
    }
  }, [inView]);

  const animateCss = animate ? 'animate__animated animate__fadeIn': '';

  const { themeMode } = useTheme();
  return (
    <div className="container-fluid" ref={ref}>
      <div className="contact-us-section">
        <div className="row heading p-md-0 ">
          
          <div className="col-sm-12 col-md-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className="heading-line">
                <hr 
                  className={themeMode}
                />
              </div>
              <div className={`heading-text ${themeMode}`}>ADMIN</div>
            </div>
          </div>
          
        </div>

        <div className="row content p-md-0">
          
          <div className="col-md-12 col-lg-12">
            <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
              <div className={`content-text p-md-0 ${themeMode}`}>
                This page admin.
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default AdminSection;
