import React, { useState, useEffect, useRef } from 'react';
import { TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import './SignUpSection.scss';

import { authentication } from '../../../../firebase-config';
import { createUser } from '../../../../lib/firebase';

import { useInView } from 'react-intersection-observer';
import { useTheme } from '../../../../ThemeContext';
import { useUserContext } from '../../../../UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../NotificationContext';

function SignUpSection() {
    const { themeMode } = useTheme();
    const navigate = useNavigate();

    const { openSnackbar } = useNotification();

    const { login, logout } = useUserContext();

    const [formData, setFormData] = useState({
        username: '',
        display: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    
    const [errors, setErrors] = useState({});

    const [animate, setAnimate] = React.useState(false);

    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });

    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    useEffect(() => {
        if (inView) {
            // console.log("In View: AboutUs");
            !animate && setAnimate(true)
        }
    }, [inView]);

    // const fetchData = (user) => {
    //     fetch(process.env.REACT_APP_GOOGLE_SCRIPT, {
    //         method: "POST",
    //         redirect: "follow",
    //         headers: {
    //             "Content-Type": "text/plain;charset=utf-8",
    //         },
    //         body: JSON.stringify(user),
    //     })
    //     .then((res) => {
    //         console.log(user);
    //         console.log(res);

    //         // login(user);
    //         navigate('/login');
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.display === '') {
            newErrors.display = 'Fullname is required';
        }
        if (formData.username === '') {
            newErrors.username = 'Username is required';
        }
        
        if (formData.email === '') {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        if (formData.password === '') {
            newErrors.password = 'Password is required';
        }
        if (formData.confirmPassword === '') {
            newErrors.confirmPassword = 'Please confirm your password';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        if (Object.keys(newErrors).length === 0) {
            // Handle form submission logic here
            console.log('Form submitted:', formData);

            try{
                const user = {
                    "name": formData.fullname || '',
                    "username": formData.username || '',
                    "display": formData.display || '',
                    "email": formData.email,
                    "password": formData.password
                }
            
                const response = await createUser(user);
                if(response?.error){
                    openSnackbar("error", response?.error);
                }else{
                    openSnackbar("success", "Sign up successfully");
                    setTimeout(()=>{
                        navigate('/login');
                    },1000);
                }
            }catch(err){
                console.log(err);

                openSnackbar("error", "Sign up failed");
            }

        } else {
          setErrors(newErrors);
        }
    };

    // const loginTwitter = () => {
    //     // navigate('/');
    //     const provider = new TwitterAuthProvider();
    //     signInWithPopup(authentication, provider)
    //         .then((result) => {
    //             if (result.user) {
    //                 console.log(result);

    //                 const user = {
    //                     "email": result?.user.email,
    //                     "method": 'twitter',
    //                     "name": result?.user.displayName,
    //                     "username": result?.user.email || '',
    //                     "displayName": result?.user.displayName || ''
    //                 }

    //                 fetchData(user);
    //             }
    //         }).catch((err) => {
    //             console.log(err)
    //         });
    // };

    // const submitSignUp = (e) => {
    //     e.preventDefault();
        
    //     setInvalid(false);

    //     if(email!=='' && password!==''){
    //         // const valid = validUserAdmin(email, password)
    //         // if(valid){
                
    //         //     const userAdmin = {
    //         //         "email": email.toLowerCase(),
    //         //         "method": 'admin',
    //         //         "name": "Admin",
    //         //         "username": "Admin",
    //         //         "displayName": "Admin",
    //         //         ...valid 
    //         //     };

    //         //     login(userAdmin);
    //         //     navigate('/admin');
    //         // }else{
    //         //     setInvalid(true);
    //         // }
    //     }
    // };

    // const handleClickLogin = (type) => {
    //     console.log(type);

    //     switch(type){
    //         case 'twitter':
    //             loginTwitter();
    //             break;

    //         case 'email':
    //             setShowForm(true);
    //             break;
    
    //         default:
    //             break;
    //     }
    // }

    const handleClickCancel = () => {
        setFormData({
            username: '',
            display: '',
            email: '',
            password: '',
            confirmPassword: '',
            method: 'email'
        });
    }

    return (
        <div className="container-fluid p-0" ref={ref}>
            <div className="sign-up-section">
                <div className="row heading p-md-0">
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        <div className={`heading-text ${themeMode}`}>
                            Welcome to Laniakea Capital - Sign Up 
                        </div> 
                    </div>
                </div>

                <div className={`row content p-md-0 ${themeMode}`}>
                    <div className={`col-sm-12 col-md-12 hidden-content ${animateCss}`}>
                        <div className='d-flex flex-row align-items-center justify-content-evenly'>
                            <div className='group-form d-flex flex-row align-items-center justify-content-evenly'>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            name="username"
                                            type="text"
                                            required
                                            placeholder="Please input your username"
                                            autoComplete="off"
                                            value={formData.username}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors.username && <p>{errors.username}</p>}

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            name="display"
                                            type="text"
                                            required
                                            placeholder="Please input your fullname"
                                            autoComplete="off"
                                            value={formData.display}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors.email && <p>{errors.email}</p>}

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            name="email"
                                            type="email"
                                            required
                                            placeholder="Please input your email"
                                            autoComplete="off"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors.email && <p>{errors.email}</p>}

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            name="password"
                                            type="password"
                                            required
                                            placeholder="Please input your password"
                                            autoComplete="off"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors.password && <p>{errors.password}</p>}

                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            name="confirmPassword"
                                            type="password"
                                            required
                                            placeholder="Please input your confirm password"
                                            autoComplete="off"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {errors.confirmPassword && <p>{errors.confirmPassword}</p>}

                                    <div className="form-group-button">
                                        <button type="submit" className="btn btn-verify">Sign Up</button>

                                        <button className="btn btn-cancel" onClick={handleClickCancel}>Cancel</button>
                                    </div>

                                    {/* {invalid && (
                                        <div className="form-group" style={{pt: "20px"}}>
                                            <div className="invalid">
                                                Login fail! Wrong email or password!
                                            </div>
                                        </div>
                                    )} */}
                                    

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUpSection;
