import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { useTheme, THEME } from "../../../../../ThemeContext";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import { getArticles } from "../../../../../lib/firebase";

const EditButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#D41876",
  marginRight: "20px",
  padding: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#D41876",
    opacity: 0.8,
  },
});
const DeleteButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#6F5D5D",
  padding: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#6F5D5D",
    opacity: 1.1,
  },
});
const ListData = ({ handleGetDataRow, handleDeleteRow, dataRows: data }) => {
  const { themeMode } = useTheme();
  return (
    <Container
      maxWidth="100vw"
      sx={{
        marginBottom: "50px",
        minWidth: "90vw",
      }}
    >
      <TableContainer>
        <Table
          sx={{
            padding: "0",
            backgroundColor: themeMode === THEME.LIGHT ? "#fff" : "#000",
            color: themeMode === THEME.LIGHT ? "#000" : "#fff",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontWeight: "bold",
                  borderBottom:
                    themeMode === THEME.LIGHT
                      ? "1px solid #000"
                      : "1px solid #fff",
                  backgroundColor: themeMode === THEME.LIGHT ? "#fff" : "#000",
                  color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <TableCell>Event Title</TableCell>
              <TableCell>Event Content</TableCell>
              <TableCell>Upload Option</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  height: "100px",
                  td: {
                    borderBottom:
                      themeMode === THEME.LIGHT
                        ? "1px solid #000"
                        : "1px solid #fff",
                    backgroundColor:
                      themeMode === THEME.LIGHT ? "#fff" : "#000",
                    color: themeMode === THEME.LIGHT ? "#000" : "#fff",
                    "&:last-child": { borderRight: 0 },
                    transition: "all 0.3s ease-in-out",
                  },
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>{row.eventTitle}</TableCell>
                <TableCell>{row.eventContent}</TableCell>
                <TableCell>{row.uploadOption}</TableCell>
                <TableCell>{row.startDate.toDateString()}</TableCell>
                <TableCell>{row.endDate.toDateString()}</TableCell>
                <TableCell
                  sx={{
                    padding: "0",
                  }}
                >
                  <Container
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "0px !important",
                    }}
                  >
                    <EditButton
                      onClick={() => {
                        document
                          .getElementById("form-article")
                          .scrollIntoView();
                        handleGetDataRow(row);
                      }}
                    >
                      View/Edit
                    </EditButton>
                    <DeleteButton
                      onClick={() => {
                        handleDeleteRow(row.id);
                      }}
                    >
                      Delete
                    </DeleteButton>
                  </Container>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ListData;
