import React, { useState, useEffect } from "react";
import "./Research.scss";

import Layout from "../../components/Layout/Layout";
import { useTheme } from "../../ThemeContext";
import LaunchSoon from "./sections/LaunchSoon/LaunchSoon";
import EventArticles from "./sections/EventArticles/EventArticles";

import { getCurrentArticles } from "../../lib/firebase";

function Research() {
  const { themeMode } = useTheme();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const articles =  await getCurrentArticles("Research");

      if(articles.length>0){
        setArticles(articles);
      }
    }

    fetchData()
    .catch(console.error);
  },[]);

  return (
    <Layout>
      <div className="container-fluid p-0">
        <div className="research container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">
              { articles.length > 0  && 
                <section id="section1" className={`full-scroll-section m-0 ${themeMode}`}>
                  <EventArticles articles={articles}></EventArticles>
                </section>
              } 

              <section id="section2" className={`full-scroll-section ${themeMode}`}>
                <LaunchSoon></LaunchSoon>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Research;
