import React, { useState, useEffect } from "react";
import "./ContactUs.scss";

import Layout from "../../components/Layout/Layout";
import ContactUsSection from "./sections/ContactUsSection/ContactUsSection";
import { useTheme } from "../../ThemeContext";
function ContactUs() {
  const { themeMode } = useTheme();
  return (
    <Layout>
      <div className="container-fluid p-0">
        <div className="contact-us container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">

              <section id="section1" className={`full-scroll-section ${themeMode}`}>
                <ContactUsSection></ContactUsSection>
              </section>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactUs;
