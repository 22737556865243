import React, { useState, useEffect } from 'react';
import './GuidingPillars.scss';
import { useTheme } from '../../../../ThemeContext';
import { useInView } from 'react-intersection-observer';

function GuidingPillars() {
    const { themeMode } = useTheme();

    const [animate, setAnimate] = React.useState(false);
    const [ref, inView] = useInView({
        threshold: 0.2, // Adjust this threshold as needed (0.5 means 50% of the element is visible)
    });
    React.useEffect(() => {
        if (inView) {
        // console.log("In View: AboutUs");
        !animate && setAnimate(true)
        }
    }, [inView]);
    const animateCss = animate ? 'animate__animated animate__fadeIn': '';

    return (
        <div className="container-fluid" ref={ref}>
            <div className="guiding-pillars">
                <div className="row heading p-md-0">
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            <div className="heading-line"><hr className={themeMode}/></div>
                            <div className={`heading-text ${themeMode}`}>GUIDING PILLARS</div>
                        </div>
                    </div>
                </div>

                <div className="row content p-md-0">
                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            <p className={themeMode}>
                                With Laniakea, the journey is not solely about investments—it's about pioneering a decentralized, consumer-centric future.
                            </p>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12">
                        <div className={`split-container p-md-0 hidden-content ${animateCss}`}>
                            {/* <div className="content-space d-none d-sm-block"></div> */}
                            <div className="content-text row p-md-0">
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Value Proposition</p>
                                        <p className={themeMode}>Every Laniakea venture offers an unmatched value, standing tall against incumbent solutions.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Product Resonance</p>
                                        <p className={themeMode}>Our investments echo a tangible product-market alignment in the fast-evolving web3 milieu.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Team Excellence</p>
                                        <p className={themeMode}>We ally with the best, ensuring our collaborations are with teams that truly innovate.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Advisory Excellence</p>
                                        <p className={themeMode}>Laniakea's advisory arm synergizes traditional finance best practices with the dynamism of the Web3 community. Aspiring to be a beacon in the Web3 industry, our our advisory services are renowned for innovation where we don't just advise; we co-create, delivering strategic solutions to the brightest minds in Web3.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">Technological and Cultural Intersection</p>
                                        <p className={themeMode}>We're at a crossroads where dwindling trust in established institutions meets the surge of crypto networks, leading to a profound reshaping of economic paradigms. Simultaneously, software's growing dominance over finance challenges even our most foundational monetary concepts.</p>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <div className="block-text">
                                        <p className="sub-heading">The Web3 Paradigm</p>
                                        <p className={themeMode}>Our commitment goes beyond programmable money. We champion the Web3 vision where consumers regain control of their data, upending the existing monopolistic data structures. As tech giants lose their data hegemony, we foresee an unprecedented wave of innovation, birthing businesses and services that currently lie beyond imagination.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default GuidingPillars;