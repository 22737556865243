import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useTheme , THEME} from '../../ThemeContext';

import './Layout.scss';

const Layout = (props) => {
  const { showBgFooter, showNavbar, children } = props;
  const {themeMode} = useTheme();
  return (
    <div>
        <Header showNavbar={showNavbar}/>
        <main
          className={`main ${themeMode}`}
          style={{ 
            backgroundColor: themeMode === THEME.LIGHT ? '#fff' : '#000',
            transition: 'all 0.3s ease-in-out',
          }}
        >
            {children}
        </main>
      
        <Footer showBgFooter={showBgFooter} />
    </div>
  );
};

export default Layout;