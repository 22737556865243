const { initializeApp } = require("firebase/app");
const {
  getDatabase,
  ref: refDb,
  set,
  get,
  update,
  remove,
  query,
  equalTo,
  orderByChild,
} = require("firebase/database");
const {
  getStorage,
  ref: refStorage,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} = require("firebase/storage");
const { v4: uuidv4 } = require("uuid");
const _ = require("lodash");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase();
const storage = getStorage();


// ARTICLE
const ARTICLE = "article";

function getFileExtension(filename) {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
}

function getFileNameOrigin(filename) {
  return /[.]/.exec(filename) ? /(^.+(?=\.))/.exec(filename)[0] : undefined;
}

function generateFileName(file) {
  return (
    getFileNameOrigin(file.name) +
    "_" +
    new Date().getTime() +
    "." +
    getFileExtension(file.name)
  );
}

/**
 * Create article
 * @param {Object} article
 * @param {String} article.title
 * @param {String} article.content
 * @param {File} article.imageDark
 * @param {File} article.imageLight
 * @param {Number} article.startDate
 * @param {Number} article.endDate
 * @param {File=} [article.attachment]
 * @param {String} article.uploadOption
 * @returns
 */

export async function createArticle(article) {
  try {
    const imageDarkName = await uploadImage(article?.imageDark);
    const imageLightName = await uploadImage(article?.imageLight);
    const attachmentName = await uploadAttachment(article?.attachment);
    const data = {
      title: article?.title || "",
      content: article?.content || "",
      imageDark: imageDarkName || "",
      imageLight: imageLightName || "",
      startDate: article?.startDate || "",
      endDate: article?.endDate || "",
      attachment: attachmentName || "",
      uploadOption: article?.uploadOption || "Homepage",
    };

    const reference = refDb(db, `${ARTICLE}/` + uuidv4());
    return await set(reference, data);
  } catch (error) {
    console.error(error.message);
  }
}

/**
 * Update article
 * @param {String} id
 * @param {Object} article
 * @param {String=} [article.title]
 * @param {String=} [article.content]
 * @param {File=} [article.imageDark]
 * @param {File=} [article.imageLight]
 * @param {Number=} [article.startDate]
 * @param {Number=} [article.endDate]
 * @param {File=} [article.attachment]
 * @param {String} article.uploadOption
 * @returns
 */

export async function updateArticle(id, article) {
  const articleExists = await getArticleOrigin(id);
  if (!articleExists) {
    throw new Error("Article not found");
  }

  const { imageDark, imageLight, attachment } = article;
  let updateData = {};

  const newImageDark = await uploadImage(imageDark);
  const newImageLight = await uploadImage(imageLight);
  const newAttachment = await uploadAttachment(attachment);

  if (newImageDark) {
    await deleteImage(articleExists.imageDark);
  }
  updateData["imageDark"] = newImageDark;
  if (newImageLight) {
    await deleteImage(articleExists.imageLight);
  }
  updateData["imageLight"] = newImageLight;
  if (newAttachment) {
    await deleteAttachment(articleExists.attachment);
  }
  updateData["attachment"] = newAttachment;
  updateData = {
    ...article,
    ...updateData,
  };
  updateData = _.omitBy(updateData, _.isUndefined);
  console.log(updateData);
  const reference = refDb(db, `${ARTICLE}/` + id);
  return await update(reference, updateData);
}

/**
 * Remove Article
 * @param {String} id
 * @returns
 */
export async function removeArticle(id) {
  const articleExists = await getArticleOrigin(id);
  if (!articleExists) {
    throw new Error("Article not found");
  }
  await deleteImage(articleExists.imageDark);
  await deleteImage(articleExists.imageLight);
  await deleteAttachment(articleExists.attachment);
  const reference = refDb(db, `${ARTICLE}/` + id);
  return await remove(reference);
}

/**
 * Get Articles
 * @returns {Article[]}
 */
export async function getArticles() {
  const reference = refDb(db, `${ARTICLE}/`);
  const snapshot = await get(reference);
  const articles = snapshot.val();
  return convertToArray(articles);
}

/**
 * Get Current Articles
 * @returns {Article[]}
 */
export async function getCurrentArticles(_uploadOption='All') {
  const articles = await getArticles();
  const now = new Date().getTime();
  return articles.filter(({ startDate, endDate, uploadOption }) => {
    return startDate <= now && now <= endDate && (_uploadOption === uploadOption || uploadOption === 'All');
  });
}

function convertToArray(obj) {
  const arrayPromise = Object.entries(obj || {}).map(async ([id, article]) => {
    const articleLink = await getUrlArticle(article);
    return {
      id: id,
      ...article,
      ...articleLink,
    };
  });
  return Promise.all(arrayPromise);
}

async function getUrlArticle(article) {
  return {
    imageDark: article.imageDark ? await downloadImage(article.imageDark) : "",
    imageLight: article.imageLight
      ? await downloadImage(article.imageLight)
      : "",
    attachment: article.attachment
      ? await downloadAttachment(article.attachment)
      : "",
  };
}

async function getArticleOrigin(id) {
  try {
    const reference = refDb(db, `${ARTICLE}/` + id);
    const snapshot = await get(reference);
    const article = snapshot.val();
    return article;
  } catch (error) {
    throw new Error("Get article error");
  }
}

/**
 *
 * @param {String} id
 * @returns
 */
export async function getArticle(id) {
  const article = await getArticleOrigin(id);
  const articleLink = await getUrlArticle(article);
  return {
    id: id,
    ...article,
    ...articleLink,
  };
}

async function uploadImage(file) {
  try {
    if (!file || typeof file?.name !== "string") {
      return undefined;
    }
    const name = generateFileName(file);
    const storageRef = refStorage(storage, "images/" + name);
    await uploadBytes(storageRef, file);
    return name;
  } catch (error) {
    throw new Error("Upload image failed");
  }
}

async function deleteImage(name) {
  try {
    if (!name) {
      return;
    }
    const storageRef = refStorage(storage, "images/" + name);
    return await deleteObject(storageRef);
  } catch (error) {
    throw new Error("Delete image failed");
  }
}

async function downloadImage(name) {
  try {
    const storageRef = refStorage(storage, "images/" + name);
    return await getDownloadURL(storageRef);
  } catch (error) {
    throw new Error("Download image failed");
  }
}

async function uploadAttachment(file) {
  try {
    if (!file || typeof file?.name !== "string") {
      return undefined;
    }
    const name = generateFileName(file);
    const storageRef = refStorage(storage, "attachments/" + name);
    await uploadBytes(storageRef, file);
    return name;
  } catch (error) {
    throw new Error("Upload attachment failed");
  }
}
/**
 *
 * @param {String} name
 * @returns
 */
export async function downloadAttachment(name) {
  try {
    const storageRef = refStorage(storage, "attachments/" + name);
    return await getDownloadURL(storageRef);
  } catch (error) {
    throw new Error("Download attachment failed");
  }
}

async function deleteAttachment(name) {
  try {
    if (!name) {
      return;
    }
    const storageRef = refStorage(storage, "attachments/" + name);
    return await deleteObject(storageRef);
  } catch (error) {
    throw new Error("Delete attachment failed");
  }
}

const bcrypt = require('bcryptjs');

// USER
const USER = "users"
/**
 * Create article
 * @param {Object} user
 * @param {String} user.email
 * @param {Number} user.password
 * @param {String} user.username
 * @param {String} user.display
 * @param {Boolean} user.block
 * @returns
 */
export async function createUser(user) {
  try {
    const users = await getUserByEmail(user.email);
    if (users.length) {
      return { error: "Email is exists" }
    }

    const password = bcrypt.hashSync(user.password, 10);
    const data = {
      email: user?.email,
      password: password,
      method: "email",
      username: user?.username,
      display: user?.display,
      block: false,
    };

    const reference = refDb(db, `${USER}/` + uuidv4());
    return await set(reference, data);
  } catch (error) {
    console.error(error.message);
  }
}

function convertToArrayUser(obj) {
  const array = Object.entries(obj || {}).map(([id, user]) => {
    return {
      id: id,
      ...user,
    };
  });
  return array;
}

/**
 *
 * @param {String} email
 * @returns
 */
export async function getUserByEmail(email) {
  const reference = query(refDb(db, `${USER}`), orderByChild('email'), equalTo(email))
  const snapshot = await get(reference);
  const users = snapshot.val();
  return convertToArrayUser(users);
}

/**
 *
 * @param {Number} block is number, -1 for All, 0 for unblock user, 1 for block user
 * @returns
 */
export async function getUsers(block) {
  const blockMap = { '-1': "All", '0': false, '1': true }
  let reference = refDb(db, `${USER}`);
  if (blockMap[block] !== "All") {
    reference = query(refDb(db, `${USER}`), orderByChild('block'), equalTo(blockMap[block]))
  }
  const snapshot = await get(reference);
  const users = snapshot.val();
  const userArr = convertToArrayUser(users)
  return userArr;
}

/**
 *
 * @param {String} email
 * @param {String} password
 * @returns
 */
export async function loginUser(email, password) {
  const users = await getUserByEmail(email);
  if (!users.length) {
    return { error: "User not found" }
  }
  if (users[0].block) {
    return { error: "User is block" }
  }
  const success = bcrypt.compareSync(password, users[0].password);
  if (success) {
    return { success: "Login successfully" }
  } else {
    return { error: "Password is wrong" }
  }
}

/**
 *
 * @param {String} email
 * @param {String} block
 * @returns
 */
export async function updateBlockUser(email, block) {
  const users = await getUserByEmail(email);
  if (!users.length) {
    return { error: "User not found" }
  }
  const reference = refDb(db, `${USER}/` + users[0].id);
  return await update(reference, { block: block });
}
