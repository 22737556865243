import React, { useState, useEffect } from "react";
import "./Careers.scss";

import Layout from "../../components/Layout/Layout";

import CareersSection from "./sections/CareersSection/CareersSection";
import JuniorInvestmentAnalyst from "./sections/JuniorInvestmentAnalyst/JuniorInvestmentAnalyst";
import CommunityManager from "./sections/CommunityManager/CommunityManager";
import FinanceAndOperationalAnalyst from "./sections/FinanceAndOperationalAnalyst/FinanceAndOperationalAnalyst";
import { useTheme } from "../../ThemeContext";

function Careers() {
  const { themeMode } = useTheme();
  return (
    <Layout>
      <div className="container-fluid p-0">
        <div className="careers container-content">
          <div style={{ height: "60px" }}></div>
          <div className="scrollable-content" style={{ overflow: "hidden" }}>
            <div className="full-scroll-container">
              
              <section id="section1" className={`full-scroll-section ${themeMode}`}>
                <CareersSection></CareersSection>
              </section>

              <section id="section2" className={`full-scroll-section ${themeMode}`}>
                <JuniorInvestmentAnalyst></JuniorInvestmentAnalyst>
              </section>

              <section id="section3" className={`full-scroll-section ${themeMode}`}>
                <CommunityManager></CommunityManager>
              </section>

              <section id="section4" className={`full-scroll-section ${themeMode}`}>
                <FinanceAndOperationalAnalyst></FinanceAndOperationalAnalyst>
              </section>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Careers;
